import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import login from "@/router/enter";
import topup from "@/router/topup";
import support from "@/router/support";
import settings from "@/router/settings";
import activation from "@/router/activation";
import options from "@/router/options";
import offers from "@/router/offers";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/tech",
        name: "Tech",
        component: () => import("@/views/Tech.vue"),
        meta: {
            layout: "auth-layout",
            title: "1Mobile | WSC",
        },
    },
    {
        path: "/",
        redirect: "/panel",
        meta: {
            title: "1Mobile | WSC",
            description: "Pagina di accesso all'account personale dell'operatore mobile 1Mobile.",
            keywords: "Armadio personale",
        },
    },
    {
        path: "/panel",
        name: "Index",
        component: () => import("@/views/IndexPage.vue"),
        meta: {
            title: "1Mobile | WSC",
            noAuth: false
        },
    },
    ...login,
    ...topup,
    ...support,
    ...settings,
    ...activation,
    ...offers,
    ...options,
    {
        path: "/refer",
        component: () => import("@/views/ReferFriendPage.vue"),
        meta: {
            title: "Refer a friend | WSC",
        },
    },
    {
        path: "/notification",
        component: () => import("@/views/NotificationPage.vue"),
        meta: {
            title: "Notifications | WSC",
        },
    },
    {
        path: "/history",
        component: () => import("@/views/HistoryPage.vue"),
        meta: {
            title: "Usage history | WSC",
        },
    },
];

const DEFAULT_TITLE = "WSC";
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    // if (to.name != "Tech") {
    //     return next({ name: "Tech" });
    // } else {
    //     return next();
    // }
    const hasExpired = localStorage.getItem('CookieExpired');
    if (hasExpired) {
        if (to.meta.noAuth === true) {
            console.log("Nice");
            return next();
        } else {
            console.log('BeforeEach to Login')
            return next({ name: "Login" });
        }
    } else {
        return next();
    }

    // const phone = localStorage.getItem("phone");
    // if (!phone) {
    //     if (to.name == "Login" || to.name == "ResetPassword" || to.name == "ActivationSuccess" || to.name == "ActivationFailed" || to.name == "Activation" || to.name == "TopupSuccess" || to.name == "TopupFailed") {
    //         console.log("Nice");
    //         return next();
    //     } else {
    //         return next({ name: "Login" });
    //     }
    // } else {
    //     if (to.name == "Login" || to.name == "Activation") {
    //         return next({ name: "Index" });
    //     } else {
    //         return next();
    //     }
    // }
});

router.afterEach((to, from) => {
    (document as any).title = to.meta.title || DEFAULT_TITLE;

    document.querySelector(`head meta[name="description"]`)?.setAttribute("content", to.meta?.description as string|| '');
    document.querySelector(`head meta[name="keywords"]`)?.setAttribute("content", to.meta?.keywords  as string || '');
});
export default router;
